import { ActivityIndicator, Button, Heading, Link, MessageBar, TroonAccess } from '@troon/ui';
import { createMemo, Show, Suspense, useContext } from 'solid-js';
import { twJoin } from '@troon/tailwind-preset/merge';
import { Dynamic } from 'solid-js/web';
import { IconCreditCard01 } from '@troon/icons/credit-card-01';
import { IconTag } from '@troon/icons/tag';
import { IconLockOpen } from '@troon/icons/lock-open';
import { IconInfo } from '@troon/icons/info';
import { createAsync, useLocation } from '@solidjs/router';
import { AccessBenefits } from '../../routes/access/_components/benefits';
import { TroonCardSubscriptionProductType } from '../../graphql';
import { AccessProductsCtx, getAccessProducts } from '../../providers/card';
import { usePersisted } from '../../providers/persistence-store';
import type { IconComponent } from '@troon/icons';
import type { ParentProps } from 'solid-js';

type Props = ParentProps<{
	type: TroonCardSubscriptionProductType;
	publicRate: { value: number; displayValue: string };
	rate: { value: number; displayValue: string };
}>;

export function AccessTeeTimeRateUpsell(props: Props) {
	const [persisted] = usePersisted();
	const data = createAsync(async () => (await getAccessProducts({ promoCode: persisted.promoCode?.value }))?.products, {
		deferStream: true,
	});

	return (
		<Suspense
			fallback={
				<div class="py-8">
					<ActivityIndicator />
				</div>
			}
		>
			<Show when={data()}>
				<AccessProductsCtx.Provider value={data}>
					<AccessTeeTimeRateUpsellInner {...props} />
				</AccessProductsCtx.Provider>
			</Show>
		</Suspense>
	);
}

function AccessTeeTimeRateUpsellInner(props: Props) {
	const products = useContext(AccessProductsCtx);
	const savings = createMemo(() =>
		new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
			currencyDisplay: 'narrowSymbol',
			trailingZeroDisplay: 'stripIfInteger',
		}).format(props.publicRate.value - props.rate.value),
	);
	const location = useLocation();

	const product = createMemo(() => products()?.find((p) => p.type === props.type));

	return (
		<Show when={product()}>
			{(product) => (
				<>
					<div class="flex flex-col gap-8 p-4 pt-12 md:p-8 md:pt-20">
						<TroonAccess class="w-48" />

						<div class="flex flex-col gap-4">
							<Heading as="h1" size="h3">
								Unlock Exclusive Savings with {product().subscriptionName}
							</Heading>
							<p>
								Join {product().subscriptionName} today to unlock exclusive savings, access to 150+ courses, and premium
								perks year-round.
							</p>
						</div>

						<ul>
							<li>
								<StepItem highlighted icon={IconCreditCard01}>
									<b class="text-base">Join {product().subscriptionName}</b>
									<Show
										when={product().autoRenew}
										fallback={
											<p>
												You’ll be charged {product().totalAmount.displayValue} today and your membership will be valid
												for one year.
											</p>
										}
									>
										<p>
											You’ll be charged an annual subscription of {product().totalAmount.displayValue}, starting today.
										</p>
									</Show>
									<Show when={product().disclosure}>{(disclosure) => <p class="text-sm">{disclosure()}</p>}</Show>
								</StepItem>
							</li>
							<li>
								<StepItem icon={IconTag}>
									<b class="text-base">Save {savings()} on this tee time</b>
									<p>
										As a {product().subscriptionName} member you’ll save {savings()} when you book this tee time. You
										can also save your entire group if you use your Guest Passes.
									</p>
								</StepItem>
							</li>
							<li>
								<StepItem icon={IconLockOpen} last>
									<b class="text-base">Save every time you play!</b>
									<Show
										when={product().type === TroonCardSubscriptionProductType.TroonAccessPlus}
										fallback={<p>Unlock 15% off every tee time.</p>}
									>
										<p>Unlock 15% off every eligible tee time, and up to 50% off inside 78 hours.</p>
									</Show>
								</StepItem>
							</li>
						</ul>

						<MessageBar appearance="brand" icon={IconInfo}>
							Troon Access members save an average of $700/year on tee times!
						</MessageBar>

						<AccessBenefits withFeaturedDeals={false} withLinks={false} />
					</div>
					<div class="sticky bottom-0 z-10 flex flex-col gap-2 border-t border-neutral bg-white p-4 md:px-8">
						<Button
							as={Link}
							href={`/access/checkout/${product().id}`}
							state={{ redirect: `${location.pathname}${location.search}` }}
						>
							Join & Save {savings()}
						</Button>
						{props.children}
					</div>
				</>
			)}
		</Show>
	);
}

function StepItem(props: ParentProps<{ highlighted?: boolean; icon: IconComponent; last?: boolean }>) {
	return (
		<div class="flex justify-stretch gap-6">
			<div class="flex flex-col gap-1">
				<div
					class={twJoin(
						'aspect-square shrink-0 grow-0 rounded-full p-2',
						props.highlighted ? 'bg-brand text-white' : 'bg-brand-100 text-brand',
					)}
				>
					<Dynamic component={props.icon} class="size-6" />
				</div>
				<Show when={!props.last}>
					<div class="mx-auto mb-1 w-0 grow border-l border-dashed border-brand" />
				</Show>
			</div>
			<div class="flex flex-col gap-1 pb-6 text-sm">{props.children}</div>
		</div>
	);
}
